<template>
    <div>
        <ValidationObserver :ref="'observer_fields_' + tab.id" v-slot="{ invalid, validated, passes, validate }">
            <v-row class="form">
                <template v-for="(field, fieldName) in tab.fields">
                    <v-col cols="12" md="6" v-if="field.type === 'datetime'">
                        <ValidationProvider :ref="fieldName" :rules="field.rules" v-slot="{ errors, valid }">
                            <div class="input_label d-flex">
                                <span class="mr-auto">
                                    {{ field.label }}
                                </span>
                                <v-icon v-if="edit" color="primary" class="mr-1" size="15" :title="$t('delete')" @click="deleteField(fieldName,tab)">mdi-delete-outline</v-icon>
                            </div>
                            <datetime-picker
                                v-model="tabData[fieldName]"
                                validator-name="deadline"
                                :error="!valid"
                                :validator-rules="field.rules"
                                format="24hr"
                                :label="field.label" :locale="lang"
                                :min-date-time="$moment().format('YYYY-MM-DD')"
                                :timezone="$auth.user().timezone" timeFormat="HH:mm"
                                readonly
                                hide-details
                                :error-messages="errors"
                                :disabled="loading"
                                outlined
                                solo
                                flat
                                dense
                                color="primary"
                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                clearable
                            ></datetime-picker>
                        </ValidationProvider>
                    </v-col>
                    <v-col cols="12" md="6" v-if="field.type === 'string' || field.type === 'number' || field.type === 'integer'">
                        <ValidationProvider :ref="fieldName" :rules="field.rules" v-slot="{ errors, valid }">
                            <div class="input_label d-flex">
                                <span class="mr-auto">
                                   {{ field.label }}
                                </span>
                                <v-icon v-if="edit" color="primary" class="mr-1" size="15" :title="$t('delete')" @click="deleteField(fieldName,tab)">mdi-delete-outline</v-icon>
                            </div>
                            <v-text-field
                                :error="!valid"
                                :error-messages="errors"
                                :label="field.label"
                                autofocus
                                v-model="tabData[fieldName]"
                                item-text="name"
                                item-value="id"
                                hide-details
                                outlined
                                solo
                                flat
                                dense
                                color="primary"
                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                clearable
                            ></v-text-field>
                        </ValidationProvider>
                    </v-col>
                    <v-col cols="12" md="6" v-if="field.type === 'boolean'">
                        <ValidationProvider :ref="fieldName" :rules="field.rules" v-slot="{ errors, valid }">
                            <div class="input_label d-flex">
                                <span class="mr-auto">
                                   {{ field.label }}
                                </span>
                                <v-icon v-if="edit" color="primary" class="mr-1" size="15" :title="$t('delete')" @click="deleteField(fieldName,tab)">mdi-delete-outline</v-icon>
                            </div>
                            <v-select
                                v-model="tabData[fieldName]"
                                :items="itemYN"
                                autofocus
                                :error="!valid"
                                :error-messages="errors"
                                item-text="name"
                                item-value="id"
                                :label="field.label"
                                hide-details
                                outlined
                                solo
                                flat
                                dense
                                color="primary"
                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                clearable
                            ></v-select>
                        </ValidationProvider>
                    </v-col>
                    <v-col cols="12" md="6" v-if="field.type === 'select' || field.type === 'enum'">
                        <ValidationProvider :ref="fieldName" :rules="field.rules" v-slot="{ errors, valid }">
                            <div class="input_label d-flex">
                                <span class="mr-auto">
                                    {{ field.label }}
                                </span>
                                <v-icon v-if="edit" color="primary" class="mr-1" size="15" :title="$t('delete')" @click="deleteField(fieldName,tab)">mdi-delete-outline</v-icon>
                            </div>
                            <v-select
                                v-model="tabData[fieldName]"
                                :items="field.items"
                                autofocus
                                :error="!valid"
                                :error-messages="errors"
                                item-text="name"
                                item-value="id"
                                :label="field.label"
                                hide-details
                                outlined
                                solo
                                flat
                                dense
                                color="primary"
                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                clearable
                            ></v-select>
                        </ValidationProvider>
                    </v-col>
                </template>
                <v-col cols="12" v-if="edit">
                    <v-btn
                        :loading="loading"
                        color="primary"
                        class="infinity_button"
                        @click="addField()"
                    >{{ $t('add') }}
                    </v-btn>
                </v-col>
                <v-col cols="12" v-if="!edit">
                    <v-btn :disabled="invalid || loading" :loading="loading" @click="tabFieldsSave()"
                           :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                        {{ $t('save') }}
                    </v-btn>

                    <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                    <v-progress-linear
                        v-if="!$vuetify.breakpoint.xsOnly"
                        v-model="progress"
                        :active="loading" class="mx-2"
                    ></v-progress-linear>

                </v-col>
            </v-row>
        </ValidationObserver>

        <v-dialog
            transition="dialog-bottom-transition"
            max-width="700"
            v-model="dialogAddField"
            class="dialog_body"
        >
            <ValidationObserver :ref="'observer_' + tab.id" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text class="py-0">
                        <v-row>
                            <v-col cols="12">
                                <v-row class="d-flex mt-5">
                                    <v-col cols="10" class="ma-auto">
                                        <span class="title_menu">{{ $t('add_field') }}</span>
                                    </v-col>
                                    <v-col cols="2" class="d-flex justify-end">
                                        <v-icon @click="addFieldClose()" :title="$t('close')" color="primary">mdi-close</v-icon>
                                    </v-col>
                                </v-row>
                            </v-col>

                        </v-row>
                        <v-row>
                            <v-col cols="12" class="py-0">
                                <ValidationProvider ref="type_field" rules="required|min:1" v-slot="{ errors, valid }">
                                    <div class="input_label">{{ $t('field') }}</div>
                                    <v-select
                                        v-model="field"
                                        :items="fieldItems"
                                        autofocus
                                        :error="!valid"
                                        :error-messages="errors"
                                        item-text="name"
                                        item-value="slug"
                                        :label="$t('field')"
                                        hide-details
                                        outlined
                                        solo
                                        flat
                                        dense
                                        color="primary"
                                        class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                    >
                                        <template v-slot:item="data">
                                            <template v-if="data.item">
                                                {{ data.item.name }}
                                            </template>
                                        </template>
                                    </v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" class="py-0">
                                <ValidationProvider ref="required_field" rules="required|min:1" v-slot="{ errors, valid }">
                                    <div class="input_label">{{ $t('required_field') }}</div>
                                    <v-select
                                        v-model="required_field"
                                        :items="itemYN"
                                        autofocus
                                        :error="!valid"
                                        :error-messages="errors"
                                        item-text="name"
                                        item-value="id"
                                        :label="$t('required_field')"
                                        hide-details
                                        outlined
                                        solo
                                        flat
                                        dense
                                        color="primary"
                                        class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                    ></v-select>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4 py-7">
                        <v-btn
                            :disabled="invalid || loading"
                            :loading="loading"
                            :block="$vuetify.breakpoint.xsOnly"
                            color="primary"
                            class="infinity_button"
                            @click="saveField"
                        >{{ $t('add') }}
                        </v-btn>
                        <v-btn
                            text
                            @click="addFieldClose()"
                        >{{ $t('close') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-dialog>

    </div>
</template>

<script>

import {ValidationObserver, ValidationProvider} from "vee-validate";
import {mapGetters} from "vuex";
import DatetimePicker from '@/components/DatetimePicker.vue'

export default {
    name: 'FormTabField',
    components: {
        ValidationObserver,
        ValidationProvider,
        DatetimePicker
    },
    props: {
        tab: Object,
        entity_type: String,
        edit: {
            type: Boolean,
            default: false
        },
        entity_uuid: String
    },
    data() {
        return {
            loading: false,
            progress: 0,
            tabData: this.initializeFormData(),
            itemYN: [
                {
                    id: -1,
                    name: this.$t('no'),
                },
                {
                    id: 1,
                    name: this.$t('yes'),
                }
            ],
            field: null,
            fieldItems: [],
            dialogAddField: false,
            required_field: null,
        };
    },
    computed: {
        ...mapGetters(['listLanguageNames', 'lang']),
        languages() {
            return this.listLanguageNames
        },
    },
    methods: {
        initializeFormData() {
            let data = {};
            for (let fieldName in this.tab.fields) {
                data[fieldName] = this.tab.fields[fieldName].default || '';
            }
            return data;
        },
        addField() {
            if (this.edit === false) {
                return
            }
            this.dialogAddField = true;
            this.gitFields();
        },
        addFieldClose() {
            this.dialogAddField = false;
            this.required_field = null;
            this.field = null;
        },
        async gitFields() {
            if (this.edit === false) {
                return
            }
            this.loading = true;
            let params = {};
            await this.$http
                .get(`admin/eav/${this.entity_type}/property`, {
                    params: params,
                })
                .then(res => {
                    this.fieldItems = res.body.data;
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_list_fields'))
                })
                .finally(end => {
                    this.loading = false
                })

        },
        async saveField() {
            if (this.edit === false) {
                return
            }

            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.tab.id) {
                formData.append('tab_id', this.tab.id)
            }
            if (this.field) {
                formData.append('field', this.field)
            }
            if (this.required_field === 1) {
                formData.append('required_field', 1)
            }
            formData.append('entity_type', this.entity_type)

            await this.$http
                .post(`admin/dynamic_form`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('field_has_been_added'))
                    if (res.body.data.new_field !== null) {
                        this.tab.fields = {...this.tab.fields, ...res.body.data.new_field};
                    }
                    this.addFieldClose();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('field_has_not_been_added'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })

        },
        async deleteField(field, tab) {
            if (this.edit === false) {
                return
            }
            if (confirm(this.$t('delete_field'))) {
                let params = {};
                params.tab_id = tab.id
                this.loading = true
                await this.$http
                    .delete(`admin/dynamic_form/${field}`, {
                        params: params,
                    })
                    .then(res => {
                        const fieldIndex = Object.keys(this.tab.fields).findIndex(key => key === field);
                        if (fieldIndex !== -1) {
                            let newFields = {...this.tab.fields};
                            delete newFields[field];
                            this.tab.fields = newFields;
                        }
                        this.$toastr.success(this.$t('field_has_been_deleted'))
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('field_not_been_deleted'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        async tabFieldsSave() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.entity_type) {
                formData.append('entity_type', this.entity_type)
            }
            if (this.entity_uuid) {
                formData.append('entity_uuid', this.entity_uuid)
            }
            if (this.tab.id) {
                formData.append('tab_id', this.tab.id)
            }
            Object.keys(this.tab.fields).forEach(key => {
                formData.append(`fields[${key}]`, this.tabData[key]);

            });
            await this.$http
                .post(`admin/tab_fields`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('data_is_saved'))
                })
                .catch(err => {
                    this.$toastr.error(this.$t('data_could_not_saved'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })

        },


    }
};
</script>


