<template>
    <v-col cols="12" md="4">
        <div class="tab-btn">
            <div class="d-flex">
                <div class="mr-auto cursor-pointer_header menu_tab"  @click="showTab" :class="tab.show ? 'active' : ''">
                    {{ tab.name }}
                    <v-icon v-if="edit" color="primary" size="16" :title="$t('edit')" @click="editTab">mdi-file-document-edit-outline</v-icon>
                </div>
                <div>
                    <v-icon v-if="edit" color="primary" size="15" :title="$t('delete')" @click="deleteTab">mdi-delete-outline</v-icon>
                </div>
            </div>
        </div>
        <ValidationObserver :ref="'observer_tab_' + tab.id" v-slot="{ invalid, validated, passes, validate }">
            <v-dialog
                transition="dialog-bottom-transition"
                max-width="700"
                persistent
                v-model="dialogUpdateTab"
                class="dialog_body"
            >
                <v-card flat>


                    <v-card-text class="py-0">
                        <v-row>
                            <v-col cols="12">
                                <v-row class="d-flex mt-5">
                                    <v-col cols="10" class="ma-auto">
                                        <span class="title_menu">{{ $t('tab_editing') }}</span>
                                    </v-col>
                                    <v-col cols="2" class="d-flex justify-end">
                                        <v-icon @click="editTabClose()" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                    </v-col>
                                </v-row>
                            </v-col>

                        </v-row>
                        <v-row>
                            <v-col cols="12" class="py-1" v-for="(language, index) in languages" :key="language.id">
                                <div class="input_label">{{$t('tab_name')}} - {{language.name}}</div>
                                <ValidationProvider :ref="language.id" rules="required|min:1|max:255" v-slot="{ errors, valid }">
                                    <v-text-field
                                        :error="!valid"
                                        :error-messages="errors"
                                        :label="$t('tab_name') + ' ' + language.name"
                                        autofocus
                                        v-model="language.value"
                                        hide-details
                                        outlined
                                        solo
                                        flat
                                        dense
                                        color="primary"
                                        class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                        clearable
                                    ></v-text-field>
                                </ValidationProvider>

                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4 py-7">
                        <v-btn
                            :disabled="invalid || loading"
                            :loading="loading"
                            :block="$vuetify.breakpoint.xsOnly"
                            color="primary"
                            class="infinity_button"
                            @click="updateTab"
                        >{{ $t('edit') }}
                        </v-btn>
                        <v-btn
                            text
                            @click="editTabClose()"
                        >{{ $t('close') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </ValidationObserver>

    </v-col>

</template>
<script>


import {mapGetters} from "vuex";
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    name: 'FormTab',
    props: {
        tab: Object,
        edit: {
            type: Boolean,
            default: false
        },
        entity_uuid: String
    },
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            loading: false,
            dialogUpdateTab: false,
        };
    },
    computed: {
        ...mapGetters(['listLanguageNames', 'lang']),
        languages() {
            return this.listLanguageNames
        },
    },
    methods: {
        showTab(){
            this.$emit('show-tab', this.tab);
        },
        async editTab() {
            if(this.edit === false){return}
                let params = {};
                this.loading = true
                await this.$http
                    .get(`admin/tab/${this.tab.id}`, {
                        params: params,
                    })
                    .then(res => {
                        if (res?.body?.data) {
                            let t = res.body.data;
                            for (let i = 0; i < this.languages.length; i++) {
                                this.languages[i].value = t.name[this.languages[i].id].name;
                            }
                            this.dialogUpdateTab = true;
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_tab'))
                    })
                    .finally(end => {
                        this.loading = false
                    })

        },
        async updateTab() {
            if(this.edit === false){return}
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()


            for (let i = 0; i < this.languages.length; i++) {
                formData.append(`${this.languages[i].id}[name]`, this.languages[i].value)
            }

            await this.$http
                .put(`admin/tab/${this.tab.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    if (res?.body?.data?.name) {
                        this.$toastr.success(this.$t('tab_has_been_updated'))
                        this.tab.name = res.body.data.name;
                        this.editTabClose()
                    }
                    else {
                        this.$toastr.error(this.$t('tab_has_not_been_updated'))
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('tab_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        editTabClose(){
            this.dialogUpdateTab = false;
            for (let i = 0; i < this.languages.length; i++) {
                this.languages[i].value = null;
            }
        },
        async deleteTab() {
            if(this.edit === false){return}
            if (confirm(this.$t('delete_tab'))) {
                let params = {};
                this.loading = true
                await this.$http
                    .delete(`admin/tab/${this.tab.id}`, {
                        params: params,
                    })
                    .then(res => {
                        this.$emit('delete-tab', this.tab);
                        this.$toastr.success(this.$t('tab_has_been_deleted'))
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('tab_not_been_deleted'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
    }
};
</script>


